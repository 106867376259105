// ----- Base styles -----

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";; 
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
}


a {
  color: #1976D2;
  text-decoration: none;
  transition: color 0.3s;

  &:hover, &:focus {
    color: #2196F3;
  }
}


h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  font-weight: 300;

  small {
    font-size: 60%;
  }
}

h1, h2 {
  margin: 0 0 1.5rem 0;
}

h3 {
  margin: 0 0 1rem 0;
}

h4, h5, h6 {
  margin: 0 0 .5rem 0;
  font-weight: 400;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

p {
  margin: 0 0 1rem 0;
}

strong, th {
  font-weight: 800;
}

pre, code {
  font-family: 'Cutive Mono', monospace;
  background-color: #f3f3f3;
}

pre {
  display: block;
  padding: .75rem 1rem;
  margin: 0 0 2rem 0;
  overflow: auto;
  font-size: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #bbb;
  border-radius: 4px;
}


.table-wrapper {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  margin: 0 0 1.5rem 0;
  border-collapse: collapse;

  th, td {
    padding: .25rem .5rem;
    text-align: left;
    vertical-align: top;
    border: 1px solid #ddd;
  }
}


.img-fluid {
  max-width: 100%;
  height: auto;
}



// ----- Layout -----
.fullwidth {
  .container {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.fullwidth--sm {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

  .container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }

    .subsection {
      margin: 0 0 2rem 0;
    }


.separator {
  border-bottom: 1px solid #ddd;
}



// ----- Header and footer -----
.header {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  padding: .5rem 0;
  color: #fff;
  background-color: #1976D2;
  box-shadow: 0 0 6px 6px rgba(0,0,0,0.1);

  @media (min-width: 768px) {
    padding: 1rem 0;
  }

  .container {
    display: flex;
    
    @media (max-width: 575px) {
      flex-flow: column;
    }

    @media (min-width: 576px) {
      align-items: center;
    }
  }

}

  .logo-text {
    margin: 0;

    @media (max-width: 767px) {
      font-size: 1.75rem;
    }
  }

  .header-links {

    .btn-demo {
      margin: .5rem 0;

      &:last-child {
        margin-left: .5rem;
      }
    }

    @media (min-width: 576px) {
      margin-left: auto;
    }
  }


.footer {
  text-align: center;
}



// ----- Buttons -----
.btn-demo {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #bbb;
  padding: 4px 12px 6px 12px;
  font-size: 18px;
  background-color: #fff;
  border-radius: 4px;
  transition: background-color .3s, color .3s;

  &:hover, &:focus, &:active {
    background-color: #f3f3f3;
  }
}

.btn-demo--white {
  color: #fff;
  border-color: #fff;
  background-color: transparent;
}



// ----- Utilities -----
.m-0 {
  margin: 0;
}
